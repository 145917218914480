;(function () {
  'use strict'

  var $link = $('#client_login_link')

  $link.on('click', show_login_popup)
  $('body').on('click', '.js-auth-link', show_login_popup)

  var mql = window.matchMedia('screen and (max-width: 979px)')

  function show_login_popup(event) {
    if (mql.matches) {
      return true
    }

    event.preventDefault()

    if (typeof $link.data('fw-popup-menu') !== 'undefined') {
      $link.fwPopupMenu('destroy')
    } else {
      $link.fwPopupMenu({
        css: { width: 360, padding: '30px 40px' },
        preview: false,
        overlay: true,
        closeSel: '.js-close-popup-form',
        content: function () {
          return $.tera.byId('js_login_tpl')
        }
      })

      window.input_suffixed_initialize()
    }
  }
})()
